/* Sticky footer styles
-------------------------------------------------- */

html {
  position: relative;
  min-height: 100%;
}
body {
}
a,
a:link,
a:visited,
a:active,
a:hover {
  text-decoration: underline;
}
a,
a:link,
a:visited,
a:active,
a:hover {
  color: #000;
}
a:hover {
  text-decoration: none;
}
.d-flex {
  display: flex !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.btn:focus,
.btn:active {
  /* Remove blue border when you click on a button */
  outline: none !important;
  box-shadow: none !important;
}
/* Custom page CSS
-------------------------------------------------- */
#tsparticles {
  width: 50%;
  height: 70%;
  position: fixed;
  background-image: none;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  overflow: hidden;
}

.body-particles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
/* Navigation bar */

.MuiTab-wrapper svg {
  font-size: 1.5em;
}
.navbar {
  border-radius: inherit !important;
  border: inherit !important;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 2;
  padding-top: 36px;
  padding-bottom: 36px;
}
.navbar > .navbar-toolbar {
  justify-content: space-between;
  display: flex;
  flex-grow: 1;
}

.navbar-home {
  background: none;
}

.navbar-nav {
  margin-right: 30px;
}

.navbar-brand > a:visited,
.navbar-brand > a:hover,
.navbar-brand-two > a:visited,
.navbar-brand-two > a:hover {
  text-decoration: none;
}

.navbar-brand .nav-logo-xl {
  width: 150px;
}

.navbar-brand-two .nav-logo-xs {
  width: 15%;
}

.navbar-collapse,
.navbar-nav,
.nav,
.nav-account {
  min-height: 80px;
}

.navbar-toggle .icon-bar {
  background: #000 none repeat scroll 0 0;
}

a.nav-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: black;
}

.nav-index:hover,
.ember-power-select-trigger:hover {
  color: white;
  border-bottom: 2px solid white;
}

a.nav-main.nav-link.menu-item.nav-active {
  color: white;
  border-bottom: 2px solid white;
}

.navbar-toggler .navbar-toggler-icon {
  border: 0px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/** Menu */

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.menu .menu-item {
  height: 100%;
  padding: 1.7em;
  background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(100, 200, 200, 0)
    ),
    linear-gradient(to left, rgb(255, 255, 255), rgb(255, 255, 255));
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 150ms;
}

.menu .menu-item,
.menu .menu-item:active,
.menu .menu-item:visited,
.menu .menu-item:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.menu .menu-item:active,
.menu .menu-item:hover {
  color: white;
  background-size: 0 0.1em, 100% 0.1em;
}

/* FOOTER CSS */
.container-footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  color: #f1f5f6;
  background: #303034;
  padding: 2rem 0 4rem 0;
  padding-left: 5%;
  padding-right: 5%;
}
.container-footer a {
  color: white;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer .containerFooter {
    background-color: transparent;
    text-align: center;
    margin-top: 1em;
  }
}
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  text-align: initial;
}

/** infobar */

.info-bar {
  background-color: #232621;
}
.info-bar .MuiToolbar-regular {
  min-height: inherit;
  height: inherit;
  padding: 0.5rem 0;
}

/* Error pages */

.error {
  min-height: 560px;
}

.container-section {
  padding: 3em 0 4em 0;
}

.container-white {
  background-color: #fff;
}
.container-grey {
  background-color: #dedfe4;
}
.container-border {
  border: 1px solid #a6a9ae;
}

blockquote {
  font-style: italic;
  text-align: center;
}
/*------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
}
h2 {
  font-weight: 500;
  font-size: 2.4em;
}

h3 {
  font-weight: 500;
  font-size: 1.8em;
}

h4 {
  font-weight: 500;
  font-size: 1.4em;
}

h5 {
  font-weight: 500;
  font-size: 1.3em;
}

h6 {
  font-weight: 500;
  font-size: 1.2em;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

/** Container */
.parent {
  position: absolute;
  top: 0;
}
.container.container-head {
  width: 100vw;
  overflow: none;
  top: 0;
  padding-top: 90px;
  background-color: #0f0916;
  background-image: linear-gradient(
    to right top,
    #422335,
    #40243b,
    #3c2641,
    #362847,
    #2d2b4d,
    #233053,
    #163557,
    #003a59,
    #00425a,
    #004958,
    #004f55,
    #1a5551
  );
  padding-bottom: 0;
  color: white;
  border-bottom: 3px solid white;
}
.container.container-head h1 {
  font-weight: 400;
  font-size: 4em;
  line-height: 1em;
  margin-bottom: 1em;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
}

.container.container-head.container-home {
  height: 100vh;
  border: 0;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
}

/*** CONTAINER BOTTOM */
.container-body {
  padding-top: 2em;
  padding-bottom: 12em;
}

/*--------------------------------------------------------------
# Media Query
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Notebook
--------------------------------------------------------------*/
@media only screen and (max-width: 1280px) {
  html {
    font-size: 95%;
  }
}

@media screen and (max-width: 1199px) {
  .navbar-nav .dropdown-menu {
    background: transparent;
    box-shadow: none;
    border: none;
  }
}
/*--------------------------------------------------------------
## Netbook
--------------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
  html {
    font-size: 90%;
  }
}
/*--------------------------------------------------------------
## 991px
--------------------------------------------------------------*/
@media only screen and (max-width: 991px) {
  /* Basics */

  html {
    font-size: 75%;
  }
}
/*--------------------------------------------------------------
## Tablet 768px
--------------------------------------------------------------*/
@media only screen and (max-width: 768px) {
  /* Basics */

  html {
    font-size: 80%;
  }
}

@media only screen and (max-width: 767px) {
}
/*--------------------------------------------------------------
## Mobile L 425px
--------------------------------------------------------------*/
@media only screen and (max-width: 425px) {
  /* Basics */

  html {
    font-size: 75%;
  }

  /* home */
  .main {
    background-size: 102% 100%;
  }

  /* Main navigation bar */

  .nav-logo img {
    width: 50px;
    height: 50px;
  }
}

/*--------------------------------------------------------------
## Mobile M 375px
--------------------------------------------------------------*/
@media screen and (max-width: 375px) and (orientation: portrait) {
  /* Basics */

  html {
    font-size: 75%;
  }

  /* Main navigation bar */

  .nav-logo img {
    width: 50px;
    height: 50px;
  }

  .container.container-head.container-home h1 {
    font-size: 2em;
  }
  .container.container-head.container-home h4 {
    font-size: 1.5em;
    font-weight: 400;
  }
  .container.container-head.container-home h6 {
    font-size: 1.2em;
    font-weight: 400;
  }
}
@media screen and (max-width: 896px) and (max-height: 740px) and (orientation: landscape) {
  /* Basics */

  html {
    font-size: 75%;
  }

  /* Main navigation bar */

  .nav-logo img {
    width: 50px;
    height: 50px;
  }

  .container.container-head.container-home h1 {
    visibility: hidden;
  }
  .container.container-head.container-home h4 {
    display: none;
  }
  .container.container-head.container-home h6 {
    display: none;
  }
}

/*--------------------------------------------------------------
## Small mobile
--------------------------------------------------------------*/

@media only screen and (max-width: 320px) {
  /* Basics */

  html {
    font-size: 75%;
  }

  /* Main navigation bar */

  .nav-logo img {
    width: 50px;
    height: 50px;
  }
}
